export const SITE_NAME= "Victall";
export const API_URL= "https://api.cursos.victall.com.br";
export const SOCIAL_MEDIAS= {
    instagram: "victall_br",
    facebook: "victall_br",
    youtube: "victall_br",
    whatsapp: "3891309002"
};
export const ADDRESS = {
    'street': "Rua Monte Plano",
    'number': "651 A",
    'block': "Santa Rita 1",
    'city': "Montes Claros",
    'state': "MG",
    'zipcode': "39400-392",
}

export const TRILHAS_OPTIONS = {
    'web': {
        'code': 'web',
        'name': 'Desenvolvimento Web',
    },
    'mobile': {
        'code': 'mobile',
        'name': 'Desenvolvimento Mobile',
    }
};

export const HORARIOS_OPTIONS = {
    'semana': {
        'code': 'Seg & Qua - 19h às 21h',
        'name': 'Seg & Qua - 19h às 21h (Vagas esgotadas)',
        'disabled': true,
    },
    'sabado': {
        'code': 'Sábado - 14h às 18h',
        'name': 'Sábado - 14h às 18h',
        'disabled': false,
    }
};

export const COURSES = [
    {
        title: 'Web',
        icon: "pi pi-desktop mr-2",
        'teachers': [
            {
                'name': 'Roberto Silva',
                'avatar': 'https://storage.googleapis.com/production-hostgator-brasil-v1-0-0/730/1597730/7U9YKW5H/063cdfcdadf445a7b4776eafa607ed54',
                description: 'Desenvolvedor web pleno/sênior'
            },
            {
                'name': 'Antônio Ubirajara',
                'avatar': './teachers/junior.png',
                description: 'Desenvolvedor mobile pleno/sênior'
            }
        ],
        'levels': [
            {
                'name': 'Iniciante',
                'duration': '10 semanas',
                'content': [
                    {
                        title: 'Introdução à lógica de programação, algoritmos e fluxogramas.',
                        description: 'Explicação sobre o que são algoritmos, como estruturá-los e a importância de fluxogramas no desenvolvimento de software.',
                    },
                    {
                        title: 'Variáveis, tipos de dados e operadores em Python',
                        description: 'Explicação detalhada sobre como declarar variáveis, diferentes tipos de dados (inteiros, strings, etc.), e operadores (aritméticos, de comparação).',
                    },
                    {
                        title: 'Estruturas condicionais (if, else, elif)',
                        description: 'Introdução às estruturas de decisão, permitindo que programas executem diferentes ações com base em diferentes condições.',
                    },
                    {
                        title: 'Estruturas de repetição (for, while)',
                        description: 'Exploração das diferentes formas de iterar sobre sequências e executar blocos de código repetidamente.',
                    },
                    {
                        title: "Definição e uso de funções, parâmetros e retorno de valores.",
                        description: "Explicação sobre como modularizar código em funções, passando e retornando valores."
                    },
                    {
                        title: "Implementação de algoritmos básicos em Python.",
                        description: "Prática de criação e execução de algoritmos simples para resolver problemas básicos."
                    },
                    {
                        title: "Projeto Desafio: Calculadora simples em Python.",
                        description: "Desenvolvimento de uma calculadora que realiza operações básicas, consolidando o aprendizado até o momento."
                    },
                    {
                        title: "Revisão e aprimoramento do projeto.",
                        description: "Feedback e ajustes no projeto da calculadora, com foco na melhoria de código e usabilidade."
                    },
                    {
                        title: "Introdução a listas e manipulação de strings.",
                        description: "Introdução às estruturas de dados sequenciais e manipulação de strings em Python."
                    },
                    {
                        title: "Estruturas de dados básicas (listas, tuplas, dicionários).",
                        description: "Exploração de diferentes tipos de estruturas de dados e suas aplicações práticas."
                    },
                    {
                        title: "Manipulação de arquivos (leitura e escrita).",
                        description: "Introdução à manipulação de arquivos de texto em Python, leitura e escrita de dados."
                    },
                    {
                        title: "Projeto Desafio: Gerenciador de tarefas simples.",
                        description: "Desenvolvimento de uma aplicação simples para gerenciar tarefas, aplicando conceitos de manipulação de arquivos e listas."
                    },
                    {
                        title: "Depuração e testes básicos.",
                        description: "Introdução à depuração de código e criação de testes básicos para garantir a funcionalidade correta dos programas."
                    },
                    {
                        title: "Revisão dos conceitos fundamentais.",
                        description: "Revisão de todo o conteúdo coberto até o momento, preparando para os desafios finais."
                    },
                    {
                        title: "Introdução a módulos e pacotes.",
                        description: "Organização de código em módulos e pacotes, permitindo a reutilização e estruturação eficiente do código."
                    },
                    {
                        title: "Projeto Desafio: Analisador de texto básico.",
                        description: "Desenvolvimento de uma aplicação que analisa e processa textos, consolidando o aprendizado de manipulação de strings e arquivos."
                    },
                    {
                        title: "Revisão geral e otimização de código.",
                        description: "Revisão de todo o código desenvolvido, otimização e boas práticas de programação."
                    },
                    {
                        title: "Projeto Desafio: Revisão e aprimoramento de projetos.",
                        description: "Refinamento dos projetos desenvolvidos ao longo do curso, aplicando feedbacks e otimizações."
                    },
                    {
                        title: "Apresentação dos projetos.",
                        description: "Apresentação dos projetos finais pelos alunos, demonstrando o que foi aprendido ao longo do curso."
                    },
                    {
                        title: "Discussão e feedback dos projetos.",
                        description: "Discussão sobre os projetos apresentados, com feedback detalhado dos instrutores e colegas."
                    }
                ]
            },
            {
                'name': 'Intermediário',
                'duration': '10 semanas',
                'content': [
                    {
                        title: 'Introdução à Programação Orientada a Objetos (POO)',
                        description: 'Conceitos fundamentais de POO, incluindo a importância de encapsular dados e comportamentos em objetos.',
                    },
                    {
                        title: 'Classes e objetos em Python',
                        description: 'Criação de classes e objetos, entendendo a diferença entre ambos e como utilizá-los na prática.',
                    },
                    {
                        title: 'Métodos e construtores',
                        description: 'Definição e uso de métodos em classes e a importância dos construtores para inicialização de objetos.',
                    },
                    {
                        title: "Projeto Desafio: Sistema de cadastro de alunos.",
                        description: "Aplicação prática dos conceitos de POO na criação de um sistema de cadastro simples."
                    },
                    {
                        title: "Herança e polimorfismo.",
                        description: "Utilização de herança para reutilização de código e polimorfismo para permitir múltiplas formas de comportamento."
                    },
                    {
                        title: "Encapsulamento e abstração.",
                        description: "Proteção de dados e simplificação da interface de uso de classes através desses conceitos fundamentais de POO."
                    },
                    {
                        title: "Projeto Desafio: Sistema de gerenciamento de contas bancárias.",
                        description: "Desenvolvimento de um sistema para gerenciar contas e transações, aplicando POO."
                    },
                    {
                        title: "Revisão e otimização do projeto.",
                        description: "Ajustes finais e otimizações no sistema de gerenciamento de contas bancárias."
                    },
                    {
                        title: "Manipulação de exceções.",
                        description: "Tratamento de erros e exceções em Python, garantindo que o programa continue a funcionar de forma estável."
                    },
                    {
                        title: "Projeto Desafio: Simulador de caixa eletrônico.",
                        description: "Implementação de um simulador de caixa eletrônico utilizando conceitos avançados de POO e manipulação de exceções."
                    },
                    {
                        title: "Manipulação de arquivos avançada.",
                        description: "Leitura e escrita de diferentes tipos de arquivos, como CSV e JSON, e manipulação de grandes volumes de dados."
                    },
                    {
                        title: "Projeto Desafio: Sistema de biblioteca.",
                        description: "Desenvolvimento de um sistema para gerenciamento de acervos e empréstimos em uma biblioteca."
                    },
                    {
                        title: "Introdução a testes unitários.",
                        description: "Criação de testes unitários para garantir que cada unidade de código funcione como esperado."
                    },
                    {
                        title: "Testes automatizados.",
                        description: "Criação de testes automatizados para garantir a estabilidade e qualidade contínua do código."
                    },
                    {
                        title: "Projeto Desafio: Implementação de um jogo simples.",
                        description: "Desenvolvimento de um jogo simples utilizando os conceitos avançados de Python, como POO e manipulação de exceções."
                    },
                    {
                        title: "Revisão e aprimoramento do projeto.",
                        description: "Ajustes e melhorias no jogo simples desenvolvido, focando em jogabilidade e estabilidade."
                    },
                    {
                        title: "Revisão geral de POO.",
                        description: "Revisão dos principais conceitos de POO, preparando para a implementação de projetos mais complexos."
                    },
                    {
                        title: "Discussão de padrões de design básicos.",
                        description: "Discussão e aplicação de padrões de design básicos, como Singleton, Factory, e MVC."
                    },
                    {
                        title: "Apresentação dos projetos.",
                        description: "Apresentação dos projetos finais pelos alunos, com foco na aplicação de conceitos de POO e boas práticas de programação."
                    },
                    {
                        title: "Discussão e feedback dos projetos.",
                        description: "Feedback sobre os projetos apresentados, com sugestões de melhorias e aprimoramento."
                    }
                ]
            },
            {
                'name': 'Avançado',
                'duration': '10 semanas',
                'content': [
                    {
                        title: 'Introdução ao desenvolvimento web com Django',
                        description: 'Visão geral do framework Django e suas vantagens no desenvolvimento web moderno.',
                    },
                    {
                        title: 'Configuração do ambiente e estrutura de projetos Django',
                        description: 'Instalação de Django, configuração inicial, e estruturação básica de um projeto.',
                    },
                    {
                        title: 'Modelos e migrações em Django',
                        description: 'Criação de modelos, definição de campos e uso de migrações para refletir alterações no banco de dados.',
                    },
                    {
                        title: "Projeto Desafio: Sistema de blog simples - Modelagem.",
                        description: "Definição do modelo de dados para um sistema de blog básico."
                    },
                    {
                        title: "Views e URLs no Django.",
                        description: "Criação de views e mapeamento de URLs no projeto Django para manipulação de requisições."
                    },
                    {
                        title: "Templates no Django.",
                        description: "Uso de templates para renderizar dados dinâmicos em páginas HTML no Django."
                    },
                    {
                        title: "Projeto Desafio: Sistema de blog simples - Implementação.",
                        description: "Implementação das funcionalidades básicas do sistema de blog, como criação de postagens."
                    },
                    {
                        title: "Revisão e aprimoramento do projeto.",
                        description: "Feedback e otimizações no sistema de blog simples, focando em performance e usabilidade."
                    },
                    {
                        title: "Implementação de CRUD completo.",
                        description: "Criação de operações CRUD (Create, Read, Update, Delete) completas para gerenciar recursos no Django."
                    },
                    {
                        title: "Projeto Desafio: Sistema de gerenciamento de usuários.",
                        description: "Desenvolvimento de um sistema completo para gerenciar usuários, com operações CRUD e permissões."
                    },
                    {
                        title: "Autenticação e autorização no Django.",
                        description: "Implementação de sistemas de autenticação e autorização no Django, controle de acessos baseado em permissões."
                    },
                    {
                        title: "APIs RESTful com Django Rest Framework.",
                        description: "Criação e consumo de APIs RESTful utilizando Django Rest Framework para comunicação entre sistemas."
                    },
                    {
                        title: "Projeto Desafio: Sistema de gestão de tarefas com API REST.",
                        description: "Implementação de um sistema de gestão de tarefas completo, utilizando APIs RESTful."
                    },
                    {
                        title: "Revisão e otimização do projeto.",
                        description: "Feedback, revisão e otimização do sistema de gestão de tarefas, com foco em performance e escalabilidade."
                    },
                    {
                        title: "Implementação de testes em Django.",
                        description: "Criação de testes para garantir o funcionamento correto das funcionalidades desenvolvidas no Django."
                    },
                    {
                        title: "Deploy de aplicações Django.",
                        description: "Preparação e deploy de aplicações Django em servidores de produção, garantindo escalabilidade e segurança."
                    },
                    {
                        title: "Projeto Final: Planejamento e escopo.",
                        description: "Planejamento e definição do escopo do projeto final, aplicando todos os conceitos aprendidos."
                    },
                    {
                        title: "Projeto Final: Desenvolvimento.",
                        description: "Início do desenvolvimento do projeto final, integrando todas as funcionalidades necessárias."
                    },
                    {
                        title: "Apresentação do projeto final.",
                        description: "Apresentação do projeto final, demonstrando o uso avançado de Django e as boas práticas aprendidas."
                    },
                    {
                        title: "Discussão e feedback final.",
                        description: "Feedback final sobre o projeto e sugestões de melhoria, preparando os alunos para o mercado de trabalho."
                    }
                ]
            },
        ]
    },
    {
        title: 'Mobile',
        icon: "pi pi-mobile mr-2",
        'teachers': [
            {
                'name': 'Antônio Ubirajara',
                'avatar': './teachers/junior.png',
                description: 'Desenvolvedor mobile pleno/sênior'
            },
            {
                'name': 'Roberto Silva',
                'avatar': 'https://storage.googleapis.com/production-hostgator-brasil-v1-0-0/730/1597730/7U9YKW5H/063cdfcdadf445a7b4776eafa607ed54',
                description: 'Desenvolvedor web pleno/sênior'
            }
        ],
        'levels': [
            {
                'name': 'Iniciante',
                'duration': '10 semanas',
                'content': [
                    {
                        title: 'Introdução ao Desenvolvimento Android',
                        description: (
                            <ul>
                                <li>O que é Android?</li>
                                <li>Histórico e Mercado</li>
                                <li>Ferramentas necessárias:
                                    <ul>
                                        <li>Android Studio</li>
                                        <li>JDK</li>
                                        <li>SDK</li>
                                    </ul>
                                </li>
                                <li>Primeira Aplicação: "Hello, World!"</li>
                                <li>Arquitetura básica de um aplicativo Android</li>
                            </ul>
                        ),
                    },
                    {
                        title: 'Conceitos Fundamentais',
                        description: (
                            <ul>
                                <li>Activities: Criação, ciclo de vida e interação</li>
                                <li>Layouts: XML e programação em Java</li>
                                <li>Views: Elementos visuais (TextView, Button, EditText, etc.)</li>
                                <li>Intents: Navegação entre activities e comunicação entre componentes</li>
                                <li>Recursos: Strings, cores, dimensões, etc.</li>
                            </ul>
                        ),
                    },
                    {
                        title: 'Interfaces do Usuário',
                        description: (
                            <ul>
                                <li>Material Design: Princípios e componentes</li>
                                <li>Custom Views: Criando componentes personalizados</li>
                                <li>Menus e opções</li>
                                <li>Dialogs e Toast</li>
                            </ul>
                        ),
                    },
                    {
                        title: 'Persistência de Dados',
                        description: (
                            <ul>
                                <li>Shared Preferences: Armazenando pequenas quantidades de dados</li>
                                <li>SQLite: Criando e manipulando bancos de dados locais</li>
                                <li>Conteúdo Provider: Compartilhando dados entre aplicativos</li>
                            </ul>
                        ),
                    },
                    {
                        title: 'Criação do App Desafio',
                        description: 'Explicação e orientação do projeto desafio',
                    },
                    {
                        title: 'Publicação na Google Play Store',
                        description: (
                            <ul>
                                <li>Criando uma conta de desenvolvedor</li>
                                <li>Preparando o aplicativo para publicação</li>
                                <li>Publicando o aplicativo</li>
                                <li>Atualizando o aplicativo</li>
                            </ul>
                        ),
                    },
                    {
                        title: 'Tópicos Avançados',
                        description: (
                            <ul>
                                <li>Android Jetpack: Componentes e bibliotecas modernas</li>
                                <li>Kotlin: Uma alternativa para desenvolvimento Android</li>
                                <li>Arquitetura MVVM: Model-View-ViewModel</li>
                                <li>Desenvolvimento para tablets e wearables</li>
                                <li>Segurança de aplicativos Android</li>
                            </ul>
                        ),
                    },
                ]
            },
            {
                'name': 'Intermediário',
                'duration': '10 semanas',
                'content': [
                    {
                        title: 'Componentes Avançados',
                        description: (
                            <ul>
                            <li>Services: Tarefas em segundo plano</li>
                                <li>Broadcast Receivers: Respondendo a eventos do sistema</li>
                                <li>Notifications: Enviando notificações para o usuário</li>
                                <li>Fragments: Criando interfaces flexíveis e reutilizáveis</li>
                            </ul>
                        ),
                    },
                    {
                        title: 'Localização e Mapas',
                        description: (
                            <ul>
                                <li>Google Maps API: Integrando mapas em seus aplicativos</li>
                                <li>Localização do dispositivo: GPS, rede</li>
                                <li>Marcadores, rotas e direções</li>
                            </ul>
                        ),
                    },
                    {
                        title: 'Networking',
                        description: (
                            <ul>
                                <li>Consumindo APIs REST: Fazendo requisições HTTP</li>
                                <li>JSON: Formatando e analisando dados</li>
                                <li>AsyncTask e Threads: Executando tarefas em segundo plano</li>
                                <li>Retrofit: Simplificando o consumo de APIs</li>
                            </ul>
                        ),
                    }
                ]
            },
            {
                'name': 'Avançado',
                'duration': '10 semanas',
                'content': [
                    {
                        title: 'Title aula 1 - avançado',
                        description: 'Descrição aula 1 - avançado',
                    },
                    {
                        title: 'Title aula 2 - avançado',
                        description: 'Descrição aula 2 - avançado',
                    },
                    {
                        title: 'Title aula 3 - avançado',
                        description: 'Descrição aula 3 - avançado',
                    }
                ]
            },
        ]
    }
];