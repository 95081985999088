
import {ADDRESS, SITE_NAME, SOCIAL_MEDIAS} from "../helpers/constants"
import React from "react";
export default function Footer() {

    return (
        <>
            <footer>
                <div className="grid">
                    <div className="col-12 md:col-4 text-center md:text-left copyright">
                        <img alt="logo" src="/logo500x250.png" height="90px" className=""></img>
                        <p>
                           <small>
                               2024 &copy; Todos os direitos reservados.
                           </small>
                        </p>
                    </div>
                    <div className="col-12 md:col-4 text-center md:text-center pt-5">
                        <address>
                            {ADDRESS.street} {ADDRESS.number} - {ADDRESS.block}
                            <br/>
                            {ADDRESS.city}/ {ADDRESS.state} - {ADDRESS.zipcode}
                        </address>
                    </div>
                    <div className="col-12 md:col-4 text-center md:text-right">
                        <div className="social-networks">
                            <a href={`https://api.whatsapp.com/send?phone=55${SOCIAL_MEDIAS.whatsapp}`} target="_blank" rel="noopener noreferrer">
                                <i className="pi pi-whatsapp" style={{color: 'slateblue'}}></i>
                            </a>
                            <a href={`https://instagram.com/${SOCIAL_MEDIAS.instagram}`} target="_blank"
                               rel="noopener noreferrer">
                                <i className="pi pi-instagram" style={{color: 'slateblue'}}></i>
                            </a>
                            <a href={`https://facebook.com/${SOCIAL_MEDIAS.facebook}`} target="_blank"
                               rel="noopener noreferrer">
                                <i className="pi pi-facebook" style={{color: 'slateblue'}}></i>
                            </a>
                            <a href={`https://youtube.com/${SOCIAL_MEDIAS.youtube}`} target="_blank"
                               rel="noopener noreferrer">
                                <i className="pi pi-youtube" style={{color: 'slateblue'}}></i>
                            </a>
                        </div>
                        <p>
                            Fique ligado nas novidades
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}